.Product {
  h1 {
    font-weight: 400;
  }

  .container {
    max-width: 980px;
    margin: 0 auto;
  }

  .not-found {
    min-height: 300px;
    margin-top: 50px;
  }

  hr {
    height: 1px;
    background-color: #e6e6e6;
    border: none;
    margin: 3em 0;
  }

  .nutritions-facts {
    max-height: 300px;
  }

  .nutritions-facts-link-container {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
      .product-info {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .text-product-suggestions {
        text-align: center;
        text-transform: uppercase;
        font-size: 2em;
        letter-spacing: .5em;
        font-weight: 100;
      }

    .hide-on-desktop {
      display: none;
    }
  }

  #slideshow-product {
    .slide {
      height: 350px;
      max-width: none;
    }
  }

  #slideshow-other-products {
    margin-bottom: 4em;

    .carousel-item {
      margin: 10px;

      img {
        max-height: 250px;
        border-radius: 10px;
      }
    }

    .carousel-item-text {
      text-align: left;
      font-size: 1.4em;
      font-weight: 400;
    }

    .carousel-img-container {
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      img {
        transition: opacity 0.5s ease-in-out;

        &:first-child {
          opacity: 1;
        }

        &:last-child {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &:hover {
        img {
          &:first-child {
            opacity: 0;
          }

          &:last-child {
            opacity: 1;
          }
        }
      }
    }
  }

  .flickity-button {
    width: 100%;
    height: auto;
    top: 300px;
  }

  .flickity-prev-next-button.previous {
    background: url('/images/carousel-left-arrow.png') no-repeat center center;
    background-size: contain;
    left: 50%;
    transform: translateX(-100%);
    width: 40px;
    height: 40px;
    margin-left: -20px;
  }

  .flickity-prev-next-button.next {
    background: url('/images/carousel-right-arrow.png') no-repeat center center;
    background-size: contain;
    right: 50%;
    transform: translateX(100%);
    width: 40px;
    height: 40px;
    margin-right: -20px;
  }

  .flickity-button-icon {
    fill: transparent;
  }

  .flickity-prev-next-button .flickity-button-icon:after {
    display: none;
  }
}

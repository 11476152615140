.History {
  // TODO: rename that shitty name, review this fix

  .section * {
    z-index: 2; // For hero text
  }

  .summary-arrow {
    margin: 40px 0 60px 0;
    text-align: center;
  }

  // TODO: use flex like the other collage
  .summary-section {
    display: flex;
    flex-direction: column;
    height: 600px;

    .summary-text {
      // TODO: fix this that's pretty ugly
      position: relative;

      background: #fff;

      font-size: 1.7em;
      font-family: "Montserrat", sans-serif;
      line-height: 1.154;

      padding: 0 2em;
      width: 100%;
    }
  }

  .history-main {
    padding: 2em;
    position: relative;
    max-width: 1200px;
    margin: auto;
    z-index: 3;
    font-size: 1.4em;
  }

  @media screen and (min-width: 768px) {
    .summary-section {
      flex-direction: row;
    }

    .history-main {
      text-align: center;
    }

    .summary-text {
      margin: auto 0;
      font-size: 27px;
      font-family: "Montserrat", sans-serif;
      line-height: 1.154;

      padding: 4em 2em;
      width: 40vw!important;
    }
  }
}

.ContactForm {
  position: relative;
  z-index: 10;

  .form-container {
    max-width: 900px;
    margin: 0 auto;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  input, textarea {
    background: #e6e6e6;
    box-sizing: border-box;
    padding: 20px;
    font-size: 1.2rem;

    font-family: "Montserrat", sans-serif;
    border-width: 0;
    border-radius: 2px;
  }

  input {
    height: 60px;
  }

  textarea {
    width: 100%;
    margin-top: 10px;
    resize: vertical;
  }

  .form-link {
    text-align: right;
  }

  button {
    background: none!important;
    border: none;
    padding: 0!important;
    cursor: pointer;
  }

  .message {
    max-width: 900px;
    margin: 10px auto;
    border-bottom: 2px solid;
    padding: 20px;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 1.2rem;
  }

  .error {
    color: #b7523d;
    background-color: #fff;
    border-color: #CB6652;
  }

  .success-message {
    max-width: 900px;
    margin: auto;
    background: #e6e6e6;
    box-sizing: border-box;
    padding: 2em;
    font-size: 1.2rem;
    border-width: 0;
    border-radius: 2px;

    .success-icon {
      margin-top: 2em
    }

    h3 {
      margin-bottom: 2em;
    }
  }

  .loading-dots span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgb(7, 71, 125);
    border-radius: 50%;
    animation: bounce 0.4s infinite alternate;
    margin: 0 5px;
  }

  .loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-10px);
    }
  }

  @media screen and (min-width: 768px) {
    padding: 60px;
    background-image: url("/images/favorites-background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .input-wrapper {
      flex-direction: row;
    }

    input {
      width: 50%;
    }

    input, textarea, .success-message {
      background: #fff;
    }
  }
}

.HistoryShort {
  z-index: 100;
  position: relative;
  height: 800px;

  .summary-arrow {
    margin: 40px 0 60px 0;
    text-align: center;
  }

  .summary-section {
    display: flex;
    flex-direction: column;
    height: 600px;

    .side-text {
      font-size: 20px;
      writing-mode: tb-rl;
      transform: rotate(-180deg);

      text-align: center;
      padding: 0 60px 0 20px;
    }

    .summary-text {
      font-size: 27px;
      font-family: "Montserrat", sans-serif;
      line-height: 1.154;
      box-sizing: border-box;

      padding: 2em;
    }
  }

  @media screen and (min-width: 768px) {
    .summary-section {
      flex-direction: row;
    }

    .summary-text {
      max-width: 400px;
    }
  }

  @media screen and (min-width: 1200px) {
    .summary-text {
      padding: 2em 0;
      max-width: 500px;
    }
  }
}

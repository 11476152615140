// TODO: finish responsivness
.collage {
  overflow-y: hidden;

  height: 100%;
  width: 100%;

  img {
    position: absolute;
    top: 0;
  }

  img:nth-child(1) {
    top: 70vh;
    width: 70vw;
    right: 5vw;
    z-index: 1;
  }

  img:nth-child(2) {
    top: 60vh;
    width: 50vw;
    left: 5vw;
    z-index: 2;
  }

  img:nth-child(3) {
    top: 50vh;
    width: 30vw;
    left: 20vw;
    z-index: 3;
  }

  @media screen and (min-width: 768px) {
    img:nth-child(1) {
      top: 80vh;
      width: 40vw;
      left: 15vw;
      z-index: 1;
    }

    img:nth-child(2) {
      top: 75vh;
      width: 30vw;
      left: 15vw;
      z-index: 2;
    }

    img:nth-child(3) {
      top: 70vh;
      width: 20vw;
      left: 20vw;
      z-index: 3;
    }
  }

  @media screen and (min-width: 1200px) {
    img:nth-child(1) {
      top: 80vh;
      width: 40vw;
      left: 20vw;
      z-index: 1;
    }

    img:nth-child(2) {
      top: 75vh;
      width: 30vw;
      left: 10vw;
      z-index: 2;
    }

    img:nth-child(3) {
      top: 70vh;
      width: 20vw;
      left: 15vw;
      z-index: 3;
    }
  }
}

.Menu {
  position: relative;
  z-index: 10;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 50px;
    font-size: clamp(.7em, 1.5vw, 1.1em);
    font-weight: 500;
  }

  .logo {
    flex-shrink: 0;
    max-width: 150px;
  }

  .logo a {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .logo img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .nav-list {
    list-style-type: none;
    display: flex;
  }

  .menu-link {
    padding: 0 15px;

    &.active a {
      border-bottom: 2px solid #000;
      padding-bottom: 1px;

      &:hover {
        border-color: #07477d;
      }
    }
  }

  .menu-link a {
    text-decoration: none;
  }

  .menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }

  .bar {
    background-color: black;
    height: 3px;
    width: 40px;
    margin: 5px;
    transition: 0.4s;
  }

  @media screen and (max-width: 900px) {
    .menu-link {
      padding: 0 10px;
    }
  }

  @media screen and (max-width: 768px) {

    nav {
      font-size: 1em;
    }

    .nav-list {
      border-top: 1px solid #e6e6e6;
      background: #fff;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 70px;
      left: 0;
      transition: all 0.5s;
      flex-direction: column;
      margin: 20px 0;
      padding: 0;
      display: none;
    }

    .nav-list.active {
      display: block;
      left: 0;
    }

    .menu-link {
      width: 100%;
      text-align: center;

      &.active a {
        border-bottom: none;
        padding: 20px;
      }
    }

    .menu-link:hover {
      background: #d8d9de;
    }

    .menu-link a {
      padding: 20px 0;
      display: block;
    }

    .menu-toggle {
      display: flex;
    }

    .menu-toggle.active .bar:nth-child(2) {
      opacity: 0;
    }

    .menu-toggle.active .bar:nth-child(1) {
      transform: translateY(13px) rotate(45deg);
    }

    .menu-toggle.active .bar:nth-child(3) {
      transform: translateY(-13px) rotate(-45deg);
    }
  }
}

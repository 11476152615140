.HeaderHome {
  background-color: #d8d9de;

  section {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }

    .marquee-container {
      top: 0;
      position: fixed;
      color: #fff;
      font-size: 18vh;
      z-index: 0;

      --gap: 1rem;
      display: flex;
      overflow: hidden;
      user-select: none;
      gap: var(--gap);
    }

    .marquee-content {
      flex-shrink: 0;
      justify-content: space-around;
      min-width: 100%;
      gap: var(--gap);
      display: none;
      text-transform: uppercase;
    }

    .text-container {
      text-align: center;
      position: relative;
      bottom: 30px;
      color: #fff;
      font-size: 4em;
      z-index: 3;
    }

    .background-home {
      z-index: 2;
    }
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }

  @media screen and (min-width: 768px) {
    .marquee-container .marquee-content {
      display: flex;
      animation: scroll 300s linear infinite;
    }
  }
}

.Footer {
  position: relative;
  z-index: 10;

  .footer-content {
    margin: 0 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 0;
  }

  .footer-section {
    padding: 1em;
    box-sizing: border-box;
    flex: 1 0 calc(100%);
  }

  @media (min-width: 768px) {
    .footer-content {
      flex-direction: row;
      gap: 2em;
    }
    .footer-section {
      flex: 1 0 calc(33.333% - 2em);
    }
  }
}

.Header {
  .hero {
    position: relative;
    width: 100%;
    height: calc(70vh - 100px);
    overflow: hidden;
  }

  .hero img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
  }

  .hero-title {
    font-size: clamp(2em, 5vw, 5em);
    width: 100%;
    margin-bottom: 10px;
    letter-spacing: .2em;
    text-transform: uppercase;
    font-weight: 600;
  }

  .hero-subtitle {
    font-size: 1.2em;
  }
}

.ContactSection {
  position: relative;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 400px;
  background-image: url("/images/baguette-blanche.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    font-size: 5em;
  }
}

.Products {

  margin: 2em;

  .grid-container {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
  }

  .grid-item {
    padding: 5px;
    font-size: 2em;
    text-align: center;
  }

  .img-container {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      max-width: 100%;
      height: auto;
      display: block;
      border-radius: 10px;
      transition: opacity 0.5s ease-in-out;

      &:first-child {
        opacity: 1;
      }

      &:last-child {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:hover {
      img {
        &:first-child {
          opacity: 0;
        }

        &:last-child {
          opacity: 1;
        }
      }
    }
  }

  .favorites-item-text {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {
    .grid-container {
      margin: 2em;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
  }
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;

  a {
    color: #000;
    text-decoration: none;
  }

  a:hover {
    color: #07477d;
  }

  .link {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    color: rgb(7, 71, 125);
    font-weight: bold;
    text-transform: uppercase;
    line-height: 0.873;

    &.dark {
      color: black;

      &:hover, &.active {
        color: #07477d;
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .container {
    padding: 25px;
  }
}

@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
